/* line 3, assets/css/sass/_item.scss */
.item-test {
  color: blue;
}

/* line 3, assets/css/sass/styles.scss */
.test-back-img {
  margin-top: 200px;
  width: 1000px;
  height: 1000px;
  background-attachment: fixed;
  background-image: url(http://atyun-api-sit.oss-cn-shanghai.aliyuncs.com/uploads/user_0/content/background/76512/bbdb525f-2e52-4a40-bc6b-66b37396e010.jpg);
}

/* line 11, assets/css/sass/styles.scss */
.login-background {
  position: fixed;
  width: 100%;
  height: 100%;
  background-image: url(https://uploads-global.eqding.com/uploads/user_0/image/file/15842/3283d1be-5fea-4caf-a054-4b1eaaa0f2a5.jpg);
}

/* line 18, assets/css/sass/styles.scss */
.login-content {
  margin: 200px auto;
  min-height: 260px;
  width: 400px;
  padding: 20px 30px 48px;
  border-radius: 5px;
  position: relative;
}

/* line 27, assets/css/sass/styles.scss */
.login-portrait {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  position: absolute;
  top: -40px;
  left: 40%;
}

/* line 36, assets/css/sass/styles.scss */
.login-input {
  width: 300px;
  height: 30px;
  border-radius: 10px;
  border: 1px solid #fff;
  display: block;
  margin: 0 auto;
  padding-left: 20px;
}

/* line 46, assets/css/sass/styles.scss */
.username {
  margin-top: 60px;
}

/* line 50, assets/css/sass/styles.scss */
.password {
  margin-top: 40px;
}

/* line 54, assets/css/sass/styles.scss */
.login-button {
  margin-top: 30px;
  text-align: center;
}
/* line 57, assets/css/sass/styles.scss */
.login-button button {
  width: 70px;
  height: 25px;
  background-color: #ffffff;
  border: 1px solid #ffffff;
  color: #50a3f6;
  cursor: pointer;
  border-radius: 5px;
}

/* line 68, assets/css/sass/styles.scss */
.product-pane {
  padding: 20px 50px 100px 50px;
  background: white;
}
/* line 71, assets/css/sass/styles.scss */
.product-pane h1 {
  font-size: 20px;
  font-weight: normal;
}
/* line 75, assets/css/sass/styles.scss */
.product-pane .ck-editor__editable {
  min-height: 150px;
}
/* line 79, assets/css/sass/styles.scss */
.product-pane .ant-table-wrapper {
  border: 1px solid #ececec;
  margin: 24px 0;
  border-bottom: 0;
}
/* line 85, assets/css/sass/styles.scss */
.product-pane .ant-table-thead > tr > th {
  background: #F3F6FA !important;
  font-size: 14px !important;
  color: #303133 !important;
  text-align: center !important;
}
/* line 92, assets/css/sass/styles.scss */
.product-pane .ant-table-tbody > tr {
  font-size: 14px !important;
  color: #303133 !important;
  text-align: center !important;
}
/* line 93, assets/css/sass/styles.scss */
.product-pane .ant-table-tbody > tr:hover {
  background-color: #f6fafe !important;
}
/* line 101, assets/css/sass/styles.scss */
.product-pane .ant-table-tbody > tr > td {
  font-size: 14px !important;
  color: #303133 !important;
  text-align: center !important;
}
/* line 107, assets/css/sass/styles.scss */
.product-pane .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
  background-color: #f6fafe !important;
}
/* line 111, assets/css/sass/styles.scss */
.product-pane a {
  color: #51A2F6;
}
/* line 115, assets/css/sass/styles.scss */
.product-pane .ant-table-tbody > tr > td, .product-pane .ant-table-thead > tr > th {
  padding: 10px;
}

/* line 120, assets/css/sass/styles.scss */
.nav_link {
  padding: 0 29px 0 40px;
  display: flex;
  float: right;
  align-items: center;
  justify-content: space-between;
  width: 83.5%;
}
/* line 128, assets/css/sass/styles.scss */
.nav_link img {
  margin-left: 20px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
/* line 135, assets/css/sass/styles.scss */
.nav_link .nav-links {
  margin-right: 50px;
}
/* line 137, assets/css/sass/styles.scss */
.nav_link .nav-links a {
  color: #303133;
  display: flex;
  align-items: center;
}

/* line 145, assets/css/sass/styles.scss */
.nav-left-background {
  width: 16.5%;
  height: 64px;
  background: #172540;
  position: absolute;
  top: 0;
  left: 0;
  color: #fff;
  padding-left: 24px;
  font-size: 15px;
  font-weight: 600;
}

/* line 158, assets/css/sass/styles.scss */
.nav_bar {
  border-bottom: 1px solid rgba(29, 29, 31, 0.07);
  background-color: #fff;
  height: 64px;
  line-height: 64px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
}

/* line 170, assets/css/sass/styles.scss */
.shop-create-footer {
  width: 100%;
  padding: 10px 16px;
  text-align: right;
  background: #fff;
}

/* line 177, assets/css/sass/styles.scss */
.at-upload-files-avatar {
  overflow: auto;
}
/* line 180, assets/css/sass/styles.scss */
.at-upload-files-avatar-actions {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 10;
  white-space: nowrap;
  opacity: 0;
  -webkit-transition: all .3s;
  transition: all .3s;
}
/* line 191, assets/css/sass/styles.scss */
.at-upload-files-avatar-actions a {
  color: white;
  padding: 4px;
}
/* line 197, assets/css/sass/styles.scss */
.at-upload-files-avatar-item {
  border: 1px solid #ececec;
  border-radius: 4px;
  float: left;
  width: 104px;
  height: 104px;
  margin: 0 8px 8px 0;
  padding: 8px;
  position: relative;
}
/* line 207, assets/css/sass/styles.scss */
.at-upload-files-avatar-item-info {
  position: relative;
  height: 100%;
  width: 100%;
}
/* line 208, assets/css/sass/styles.scss */
.at-upload-files-avatar-item-info:before {
  content: ' ';
  position: absolute;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-transition: all .3s;
  transition: all .3s;
  width: 100%;
  height: 100%;
  opacity: 0;
}
/* line 224, assets/css/sass/styles.scss */
.at-upload-files-avatar-item-info-window {
  position: relative;
  height: 100%;
  width: 100%;
}
/* line 225, assets/css/sass/styles.scss */
.at-upload-files-avatar-item-info-window:before {
  content: ' ';
  position: absolute;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-transition: all .3s;
  transition: all .3s;
  width: 100%;
  height: 45%;
  opacity: 0;
  bottom: 0;
}
/* line 242, assets/css/sass/styles.scss */
.at-upload-files-avatar-actions-window {
  position: absolute;
  left: 40%;
  top: 75%;
  color: white;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 10;
  white-space: nowrap;
  opacity: 0;
  -webkit-transition: all .3s;
  transition: all .3s;
}
/* line 254, assets/css/sass/styles.scss */
.at-upload-files-avatar-actions-window a {
  color: white;
  padding: 4px;
}
/* line 258, assets/css/sass/styles.scss */
.at-upload-files-avatar-actions-window i {
  padding-right: 5px;
}
/* line 261, assets/css/sass/styles.scss */
.at-upload-files-avatar-actions-window .set-cover {
  height: 22px;
  cursor: pointer;
}
/* line 264, assets/css/sass/styles.scss */
.at-upload-files-avatar-actions-window .set-cover:hover {
  color: #19caad;
}
/* line 270, assets/css/sass/styles.scss */
.at-upload-files-avatar-preview {
  width: 100%;
  height: 100%;
}

/* line 278, assets/css/sass/styles.scss */
.at-upload-files-item-hover .at-upload-files-avatar-actions {
  opacity: 1 !important;
}
/* line 283, assets/css/sass/styles.scss */
.at-upload-files-item-hover .at-upload-files-avatar-item-info:before {
  opacity: 1 !important;
}
/* line 289, assets/css/sass/styles.scss */
.at-upload-files-item-hover .at-upload-files-avatar-item-info-window:before {
  opacity: 1 !important;
}
/* line 294, assets/css/sass/styles.scss */
.at-upload-files-item-hover .at-upload-files-avatar-actions-window {
  opacity: 1 !important;
}

/* line 302, assets/css/sass/styles.scss */
.category-list {
  background: #fff;
  margin: 20px;
  vertical-align: top;
  display: inline-block;
  min-width: 250px;
  border: 1px solid #ececec;
}
/* line 309, assets/css/sass/styles.scss */
.category-list h2 {
  text-align: center;
}
/* line 312, assets/css/sass/styles.scss */
.category-list .category-option {
  vertical-align: top;
  display: inline-block;
  float: right;
  margin-right: 20px;
}
/* line 321, assets/css/sass/styles.scss */
.category-list .category-name {
  display: inline-block;
  cursor: pointer;
  padding-right: 20px;
  padding-left: 20px;
}
/* line 326, assets/css/sass/styles.scss */
.category-list .category-name:hover {
  color: #19caad;
}
/* line 330, assets/css/sass/styles.scss */
.category-list .category-item {
  min-height: 40px;
  line-height: 40px;
  font-size: 16px;
  border-top: 1px solid #ececec;
}
/* line 336, assets/css/sass/styles.scss */
.category-list .category-create {
  position: absolute;
  top: 0;
  right: 20px;
}
/* line 341, assets/css/sass/styles.scss */
.category-list .category-title {
  position: relative;
  margin-top: 12px;
  padding: 0 50px;
}
/* line 346, assets/css/sass/styles.scss */
.category-list .category-name-invisible {
  color: rgba(0, 0, 0, 0.2);
}

/* line 351, assets/css/sass/styles.scss */
.product-attribute-title {
  padding: 16px 0;
  font-size: 16px;
  font-weight: bold;
  border-bottom: 1px solid #ececec;
}

/* line 358, assets/css/sass/styles.scss */
.steps-content {
  padding-top: 10px;
  border-radius: 6px;
  background-color: #fff;
  min-height: 200px;
}
/* line 364, assets/css/sass/styles.scss */
.steps-content .property-select {
  width: 60%;
  display: inline-block;
}
/* line 369, assets/css/sass/styles.scss */
.steps-content h2 {
  background: #fff;
  line-height: 50px;
  height: 50px;
}
/* line 375, assets/css/sass/styles.scss */
.steps-content .ant-table-td-right-sticky {
  z-index: 999 !important;
}

/* line 380, assets/css/sass/styles.scss */
.steps-action {
  text-align: right;
  height: 100px;
  line-height: 100px;
  border-top: 2px solid #f0f2f5;
}
/* line 386, assets/css/sass/styles.scss */
.steps-action button {
  width: 150px;
  height: 35px;
  margin-right: 8px;
}
/* line 392, assets/css/sass/styles.scss */
.steps-action .pre-button {
  background-color: #66cccc;
  color: #ffffff;
}
/* line 395, assets/css/sass/styles.scss */
.steps-action .pre-button:hover {
  background-color: #19caad;
  color: #ffffff;
  border: 1px solid #ffffff;
}

/* line 403, assets/css/sass/styles.scss */
.example-container {
  width: 400px;
  max-width: 100%;
  margin: 0 25px 25px 0;
  display: inline-block;
  vertical-align: top;
}

/* line 411, assets/css/sass/styles.scss */
.example-list {
  width: 1000px;
  max-width: 100%;
  border: solid 1px #ccc;
  min-height: 60px;
  display: flex;
  flex-direction: row;
  background: white;
  border-radius: 4px;
  overflow: hidden;
}

/* line 423, assets/css/sass/styles.scss */
.example-box {
  padding: 20px 10px;
  border-right: solid 1px #ccc;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  cursor: move;
  background: white;
  font-size: 14px;
  flex-grow: 1;
  flex-basis: 0;
}

/* line 439, assets/css/sass/styles.scss */
.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

/* line 447, assets/css/sass/styles.scss */
.cdk-drag-placeholder {
  opacity: 0;
}

/* line 451, assets/css/sass/styles.scss */
.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

/* line 455, assets/css/sass/styles.scss */
.example-box:last-child {
  border: none;
}

/* line 459, assets/css/sass/styles.scss */
.example-list.cdk-drop-list-dragging .example-box:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

/* line 463, assets/css/sass/styles.scss */
.center-content-animation {
  min-height: 92%;
  background: #f0f2f5;
  -webkit-transition: all .3s;
}

/* line 469, assets/css/sass/styles.scss */
.nav-menu {
  height: 100%;
  position: fixed;
  z-index: 1000;
  top: 64px;
  transition: .3s;
  background: #273754;
}
/* line 476, assets/css/sass/styles.scss */
.nav-menu li {
  margin: 0 !important;
  font-size: 14px !important;
  color: rgba(255, 255, 255, 0.8);
}
/* line 480, assets/css/sass/styles.scss */
.nav-menu li:hover {
  color: #fff;
}
/* line 484, assets/css/sass/styles.scss */
.nav-menu .ant-menu-inline {
  border-right: none !important;
}
/* line 488, assets/css/sass/styles.scss */
.nav-menu .ant-menu {
  background: #273754;
}
/* line 493, assets/css/sass/styles.scss */
.nav-menu .ant-menu-item-selected {
  background: #172540 !important;
}
/* line 497, assets/css/sass/styles.scss */
.nav-menu .ant-menu-inline-collapsed {
  width: 50px !important;
}
/* line 500, assets/css/sass/styles.scss */
.nav-menu .ant-menu-item {
  width: 100% !important;
  height: 54px !important;
  line-height: 54px !important;
}
/* line 506, assets/css/sass/styles.scss */
.nav-menu .ant-menu-item::after {
  left: 0;
  right: auto !important;
}

/* line 512, assets/css/sass/styles.scss */
.left-menu-name {
  font-size: 14px;
}

/* line 516, assets/css/sass/styles.scss */
.dz-table-pagination {
  float: right;
}

/* line 520, assets/css/sass/styles.scss */
.dz-form-explain {
  color: #f5222d !important;
}

/* line 524, assets/css/sass/styles.scss */
.dz-new-button {
  width: 172px;
  height: 40px !important;
  font-size: 18px !important;
  letter-spacing: 2px;
}

/* line 532, assets/css/sass/styles.scss */
.shop-logo-image .at-upload-files-avatar-item {
  width: 230px !important;
  height: 230px !important;
}

/* line 538, assets/css/sass/styles.scss */
.dz-submit-button {
  width: 640px;
  height: 40px !important;
}

/* line 543, assets/css/sass/styles.scss */
.ant-table-body {
  background: #fff;
}

/* line 547, assets/css/sass/styles.scss */
.product-edit-form {
  background: #fff;
  padding: 10px 40px;
  margin-top: 6px;
}
/* line 551, assets/css/sass/styles.scss */
.product-edit-form input {
  width: 60%;
}
/* line 554, assets/css/sass/styles.scss */
.product-edit-form nz-select {
  width: 80% !important;
}
/* line 558, assets/css/sass/styles.scss */
.product-edit-form .ant-form-item {
  margin-bottom: 14px;
}

/* line 568, assets/css/sass/styles.scss */
.product-edit-line {
  position: relative;
  min-height: 250px;
  padding: 10px 40px;
  background: #fff;
}
/* line 573, assets/css/sass/styles.scss */
.product-edit-line .product-edit-category {
  font-size: 16px;
  vertical-align: top;
  line-height: 30px;
}
/* line 578, assets/css/sass/styles.scss */
.product-edit-line .product-categories {
  vertical-align: middle;
  line-height: 60px;
}
/* line 583, assets/css/sass/styles.scss */
.product-edit-line .product-video-left {
  vertical-align: top;
  display: inline-block;
  font-size: 16px;
  margin-top: 7px;
  width: 10%;
}
/* line 590, assets/css/sass/styles.scss */
.product-edit-line .product-video-center {
  min-width: 400px;
  vertical-align: top;
  display: inline-block;
}
/* line 595, assets/css/sass/styles.scss */
.product-edit-line .product-video-right {
  margin-left: 40px;
  vertical-align: top;
  display: inline-block;
  font-size: 16px;
  margin-top: 7px;
}

/* line 604, assets/css/sass/styles.scss */
.product-preview-content {
  margin: 0 auto;
  width: 1200px;
}
/* line 608, assets/css/sass/styles.scss */
.product-preview-content .image-preview {
  text-align: center;
  margin-top: 15%;
}
/* line 611, assets/css/sass/styles.scss */
.product-preview-content .image-preview img {
  width: 300px;
  height: 200px;
}
/* line 618, assets/css/sass/styles.scss */
.product-preview-content .image-list img {
  width: 100px;
  height: 100px;
}

/* line 625, assets/css/sass/styles.scss */
.user-center-portrait {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  position: absolute;
  top: -60px;
  left: 36%;
  border: 1px solid #ececec;
}

/* line 635, assets/css/sass/styles.scss */
.user-center-info {
  text-align: center;
  margin-top: 70px;
}

/* line 642, assets/css/sass/styles.scss */
.login-cms-container {
  display: flex;
  width: 100%;
  height: 101%;
  min-height: 700px;
}
/* line 648, assets/css/sass/styles.scss */
.login-cms-container .login-left {
  background: white;
  width: 70%;
  text-align: center;
  margin: auto;
}
/* line 655, assets/css/sass/styles.scss */
.login-cms-container .login-right {
  background: #4a92f8;
  width: 30%;
  min-width: 300px;
  position: relative;
}

/* line 664, assets/css/sass/styles.scss */
.login-logo-container .login-logo {
  position: absolute;
  left: 0;
  top: 0;
}

/* line 672, assets/css/sass/styles.scss */
.login-image {
  max-width: 100%;
  height: 100%;
}

/* line 677, assets/css/sass/styles.scss */
.login-form {
  border-radius: 6px;
  background: white;
  width: 277px;
  left: 0;
  right: 0;
  margin: auto;
  top: 50%;
  transform: translate(0, -50%);
  position: absolute;
  text-align: center;
  padding: 28px 20px 26px 20px;
  box-shadow: 10px 10px 10px #3b8dde;
}

/* line 692, assets/css/sass/styles.scss */
.login-title {
  text-align: center;
  font-size: 20px;
  color: #2e3b4a;
  padding: 20px auto;
  font-weight: 500;
}

/* line 701, assets/css/sass/styles.scss */
.login-button {
  margin: 13px 0;
  height: 38px;
  width: 100%;
  background: linear-gradient(to right, #5babf7, #56a1fa);
  color: white;
  font-size: 16px;
  border-radius: 2px;
  font-weight: 500;
}
/* line 712, assets/css/sass/styles.scss */
.login-button:hover {
  cursor: pointer;
}

/* line 717, assets/css/sass/styles.scss */
.login-button-inactive {
  opacity: 0.6;
}
/* line 720, assets/css/sass/styles.scss */
.login-button-inactive:hover {
  cursor: default;
}

/* line 725, assets/css/sass/styles.scss */
.login-error {
  font-size: 14px;
}

/* line 729, assets/css/sass/styles.scss */
.login-message-container {
  margin: 12px 0 0;
  background-color: #fef1f2;
  padding: 7px 6px;
}

/* line 735, assets/css/sass/styles.scss */
.login-message {
  font-size: 12px;
  color: #f58b95;
  padding-left: 7px;
}

/* line 742, assets/css/sass/styles.scss */
.cms-login-input {
  padding-bottom: 5px;
  margin-bottom: 10px;
}
/* line 746, assets/css/sass/styles.scss */
.cms-login-input input {
  width: 100%;
  font-size: 18px;
  color: #253e54 !important;
  background-color: transparent !important;
  padding-left: 12px;
}
/* line 755, assets/css/sass/styles.scss */
.cms-login-input input::-webkit-input-placeholder {
  color: #b1b6bc;
  font-size: 18px;
}

/* line 762, assets/css/sass/styles.scss */
.product-edit-title {
  margin: 0 0 5px 0;
  font-size: 20px;
  background: #fff;
  padding-left: 20px;
  height: 40px;
  line-height: 40px;
  border-top: 3px solid orange;
}

/* line 772, assets/css/sass/styles.scss */
.product-edit-step {
  height: 50px;
  background: white;
  text-align: center;
  line-height: 50px;
  font-size: 18px;
  padding: 10px 0;
}

/* line 780, assets/css/sass/styles.scss */
.product-edit-step-current {
  background: #50a3f6 !important;
}

/* line 784, assets/css/sass/styles.scss */
.step-block {
  background: gray;
  color: #fff;
}

/* line 789, assets/css/sass/styles.scss */
.product-info-input {
  margin-left: 10px;
}

/* line 793, assets/css/sass/styles.scss */
.product-attribute-left {
  margin-top: 8px;
  display: inline-block;
  width: 8%;
  vertical-align: top;
  text-align: right;
  color: rgba(0, 0, 0, 0.85);
}

/* line 802, assets/css/sass/styles.scss */
.product-attribute-right {
  display: inline-block;
  width: 80%;
  margin-left: 15px;
}

/* line 808, assets/css/sass/styles.scss */
.product-copy-sku {
  margin-top: 20px;
  text-align: right;
  padding-right: 49px;
  font-size: 16px;
}

/* line 815, assets/css/sass/styles.scss */
.product-sku-table {
  text-align: center;
  margin: 20px 0;
}
/* line 818, assets/css/sass/styles.scss */
.product-sku-table .ant-table-placeholder {
  display: none;
}
/* line 821, assets/css/sass/styles.scss */
.product-sku-table .ant-table-fixed {
  text-align: center;
}

/* line 827, assets/css/sass/styles.scss */
.sku-image-edit-mengban .sku-image-edit {
  position: absolute;
  left: 15px;
  top: 10px;
  color: #50a3f6;
  cursor: pointer;
  font-size: 16px;
  visibility: hidden;
}
/* line 837, assets/css/sass/styles.scss */
.sku-image-edit-mengban:hover .sku-image-edit {
  visibility: visible;
}

/* line 844, assets/css/sass/styles.scss */
.product-sku-tr th {
  text-align: center !important;
}

/* line 849, assets/css/sass/styles.scss */
.product-sku-required {
  color: #ff0000;
}

/* line 853, assets/css/sass/styles.scss */
.product-special-property {
  margin-top: 20px;
}
/* line 855, assets/css/sass/styles.scss */
.product-special-property label {
  font-size: 16px;
  width: 200px;
  padding-left: 20px;
  margin-left: 0 !important;
}

/* line 863, assets/css/sass/styles.scss */
.product-edit-picture {
  background: #f0f2f5;
}
/* line 865, assets/css/sass/styles.scss */
.product-edit-picture .at-upload-files-avatar-item {
  background: #fff;
  width: 150px !important;
  height: 150px !important;
  margin-right: 88px;
}

/* line 873, assets/css/sass/styles.scss */
.product-block-title {
  background: #f0f2f5;
  height: 40px;
  font-size: 18px;
  line-height: 40px;
  padding-left: 40px;
  font-weight: 500;
}
/* line 881, assets/css/sass/styles.scss */
.product-block-title .product-document-new {
  float: right;
  top: 4px;
  right: 40px;
}

/* line 888, assets/css/sass/styles.scss */
.product-block-hint {
  font-size: 14px;
  color: red;
  font-weight: 400;
  padding-left: 20px;
}

/* line 895, assets/css/sass/styles.scss */
.product-upload-container {
  margin-top: 15px;
  cursor: pointer;
}

/* line 900, assets/css/sass/styles.scss */
.product-cover {
  position: absolute;
  left: 0;
  top: 0;
  color: #fff;
  background: #339999;
  height: 25px;
  width: 35px;
  font-size: 14px;
  line-height: 32px;
  text-align: center;
}
/* line 912, assets/css/sass/styles.scss */
.product-cover:before {
  content: "";
  position: absolute;
  bottom: -20px;
  left: 0;
  width: 0;
  height: 0;
  border-top: 20px solid #339999;
  border-right: 20px solid transparent;
  z-index: 997;
}
/* line 924, assets/css/sass/styles.scss */
.product-cover:after {
  content: "";
  position: absolute;
  bottom: -20px;
  right: 0;
  width: 0;
  height: 0;
  border-top: 20px solid #339999;
  border-left: 20px solid transparent;
  z-index: 998;
}

/* line 938, assets/css/sass/styles.scss */
.document-inline .ant-tabs-top-content {
  width: 10% !important;
}

/* line 943, assets/css/sass/styles.scss */
.product-left-sticky {
  z-index: 100 !important;
}

/* line 948, assets/css/sass/styles.scss */
.product-ckeditor .cke_contents {
  height: 200px !important;
}

/* line 954, assets/css/sass/styles.scss */
.chint-menu-item-background .ant-menu-item {
  background: #1d2d4a;
}

/* line 959, assets/css/sass/styles.scss */
.new-button {
  height: 32px !important;
  width: 80px;
  font-size: 14px !important;
  font-weight: normal !important;
}

/* line 966, assets/css/sass/styles.scss */
.solid-button {
  background: #51A2F6 !important;
  border: 1px solid #51A2F6 !important;
  color: white !important;
}
/* line 971, assets/css/sass/styles.scss */
.solid-button:hover {
  background-color: #51A2F6 !important;
}

/* line 976, assets/css/sass/styles.scss */
.hollow-button {
  background: white !important;
  border: 1px solid #51A2F6 !important;
  color: #51A2F6 !important;
}
/* line 981, assets/css/sass/styles.scss */
.hollow-button:hover {
  background-color: white !important;
}

/* line 986, assets/css/sass/styles.scss */
.chint-search-buttom {
  text-align: center;
  margin-bottom: 10px;
}

/* line 991, assets/css/sass/styles.scss */
.chint-backend-search-button {
  padding-top: 44px;
}

/* line 995, assets/css/sass/styles.scss */
.new-button {
  height: 32px !important;
  width: 80px;
}

/* line 1000, assets/css/sass/styles.scss */
.new-config-button {
  height: 32px !important;
  width: 90px;
  margin-left: 20px;
}

@media (min-width: 1400px) {
  /* line 1008, assets/css/sass/styles.scss */
  .chint-backend-search-button .new-button {
    margin-right: 20px;
  }
}
@media (max-width: 1400px) {
  /* line 1015, assets/css/sass/styles.scss */
  .chint-backend-search-button {
    display: flex;
    justify-content: space-between;
  }
}
/* line 1021, assets/css/sass/styles.scss */
.chint-backend-new-button {
  margin-top: 15px;
}

@media (min-width: 1400px) {
  /* line 1027, assets/css/sass/styles.scss */
  .chint-backend-new-button .new-button {
    margin-right: 20px;
  }
}
@media (max-width: 1400px) {
  /* line 1034, assets/css/sass/styles.scss */
  .chint-backend-new-button {
    display: flex;
    justify-content: space-between;
  }
}
/* line 1042, assets/css/sass/styles.scss */
.nav-left-submenu .ant-menu-submenu-title {
  height: 46px !important;
  line-height: 46px !important;
}
/* line 1045, assets/css/sass/styles.scss */
.nav-left-submenu .ant-menu-submenu-title:hover {
  color: #fff;
}

/* line 1052, assets/css/sass/styles.scss */
.empty-data {
  padding: 10px;
  top: 6.5rem;
  left: 3.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px 0;
}
/* line 1061, assets/css/sass/styles.scss */
.empty-data div, .empty-data i {
  text-align: center;
  font-size: 50px;
  color: rgba(0, 0, 0, 0.35);
}
/* line 1067, assets/css/sass/styles.scss */
.empty-data div {
  font-size: 20px !important;
}

/* line 1072, assets/css/sass/styles.scss */
.cke_menu_panel {
  width: 200px !important;
  height: 100px !important;
}

/* line 1077, assets/css/sass/styles.scss */
.cke_panel_container {
  font-size: 12px !important;
}

/* line 1081, assets/css/sass/styles.scss */
.cke_menubutton_label {
  font-size: 12px !important;
}

/* line 1086, assets/css/sass/styles.scss */
.edit-label-table .ant-table-placeholder {
  display: none;
}

/* line 1091, assets/css/sass/styles.scss */
.ant-drawer-body {
  padding: 0 24px !important;
}

/* line 1095, assets/css/sass/styles.scss */
.faq_item {
  padding: 10px;
  margin-bottom: 10px;
  background-color: #F5F5F5;
}
